import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack
} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Autocomplete from '@mui/material/Autocomplete';  // For multi-select tags/media

function DrinkDetails() {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

  // Original drink
  const [drink, setDrink] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [syrupDetails, setSyrupDetails] = useState([]);

  // Suggest Changes flow
  const [isSuggesting, setIsSuggesting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [suggestedDrink, setSuggestedDrink] = useState(null);
  const [suggestedIngredients, setSuggestedIngredients] = useState([]);

  // M2M / Form Options
  const [availableTags, setAvailableTags] = useState([]);
  const [availableMedia, setAvailableMedia] = useState([]);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [attributesMap, setAttributesMap] = useState({});

  // Cache of attributes by ingredient ID
  const memoizedAttributesMap = useMemo(() => attributesMap, [attributesMap]);


  // For controlling Autocomplete for tags and media
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  const navigate = useNavigate();

  // Utility for CSRF token
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const trimmed = cookie.trim();
      if (trimmed.startsWith(`${name}=`)) {
        return decodeURIComponent(trimmed.substring(name.length + 1));
      }
    }
    return null;
  }

  // 1) Fetch the original Drink details on mount
  useEffect(() => {
    fetch(`${API_URL}/api/drinks/${id}/`, { credentials: 'include' })
      .then((res) => res.json())
      .then((data) => {
        if (!data || data.id === undefined) throw new Error('Drink not found');

        // Favorite
        if (data.is_favorite !== undefined) {
          setIsFavorite(data.is_favorite);
        }

        // Sort the ingredient list
        const order = ['Base Spirit', 'Modifier', 'Syrup', 'Juice', 'Other', 'Garnish'];
        const sorted = (data.ingredients || []).sort((a, b) => {
          const aCat = a?.ingredient_category || 'Other';
          const bCat = b?.ingredient_category || 'Other';
          return order.indexOf(aCat) - order.indexOf(bCat);
        });

        setDrink({ ...data, ingredients: sorted });
        setIngredients(sorted);

        // If referencing any syrups, fetch them
        if (data.syrups && data.syrups.length > 0) {
          const requests = data.syrups.map((s) =>
            s.id
              ? fetch(`${API_URL}/api/syrupdetail/${s.id}/`, { credentials: 'include' }).then((r) => r.json())
              : Promise.resolve(null)
          );
          Promise.all(requests).then((allSyrups) => setSyrupDetails(allSyrups));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching drink details:', err);
        setLoading(false);
      });
  }, [id, API_URL]);

  // 2) If user triggers “Suggest Changes,” fetch ingredients, units, etc.
  // 2) If user triggers “Suggest Changes,” fetch ingredients, units, etc.
  useEffect(() => {
    if (isSuggesting) { // Removed the condition that restricted fetching
      // Fetch ingredients and units if the user is suggesting changes
      Promise.all([
        fetch(`${API_URL}/api/ingredient-options/`, { credentials: 'include' }).then((r) => r.json()),
        fetch(`${API_URL}/api/units/`, { credentials: 'include' }).then((r) => r.json()),
      ])
        .then(([ingData, unitsData]) => {
          setAvailableIngredients(ingData?.ingredients?.sort((a, b) => a.name.localeCompare(b.name)) || []);
          setAvailableUnits(unitsData?.sort((a, b) => a.name.localeCompare(b.name)) || []);

          // Preload attributes for existing ingredients in the suggested list
          const ingredientIds = (suggestedIngredients || [])
            .map((si) => si.ingredient?.id)
            .filter((id) => id && !attributesMap[id]); // Only fetch if not cached

          if (ingredientIds.length > 0) {
            return Promise.all(
              ingredientIds.map((id) =>
                fetch(`${API_URL}/api/get-ingredient-attributes/${id}/`, { credentials: 'include' })
                  .then((res) => res.json())
                  .then((data) => ({ id, attributes: data.attributes || [] }))
                  .catch((err) => {
                    console.error(`Error fetching attributes for ingredient ${id}:`, err);
                    return { id, attributes: [] };
                  })
              )
            );
          }
          return []; // No ingredient IDs to fetch
        })
        .then((attributeResults) => {
          if (attributeResults.length > 0) {
            const newAttributesMap = { ...attributesMap };
            attributeResults.forEach(({ id, attributes }) => {
              newAttributesMap[id] = attributes;
            });
            setAttributesMap(newAttributesMap); // Update the attributes map
          }
        })
        .catch((err) => {
          console.error('Error fetching suggest-changes data:', err);
        });
    }
  }, [isSuggesting, API_URL, suggestedIngredients, attributesMap]);



  // 3) Start the “Suggest Changes” flow
  function handleSuggestChanges() {
    setSaving(true);
    setIsSuggesting(true);

    // Also fetch tags and media
    Promise.all([
      fetch(`${API_URL}/api/tag-options/`, { credentials: 'include' }).then((r) => r.json()),
      fetch(`${API_URL}/api/media-options/`, { credentials: 'include' }).then((r) => r.json()),
    ])
      .then(([tagsData, mediaData]) => {
        const tagsArr = tagsData?.tags || [];
        const mediaArr = mediaData?.media || [];

        setAvailableTags(tagsArr);
        setAvailableMedia(mediaArr);

        // Now create the suggestedDrink
        return fetch(`${API_URL}/api/suggested-drink/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          credentials: 'include',
          body: JSON.stringify({ drink_id: drink.id }),
        });
      })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to create suggested drink.');
        return res.json();
      })
      .then((data) => {
        // store the new ID if you want
        setDrink((prev) => ({ ...prev, suggestedDrinkId: data.id }));

        // fetch the new suggestedDrink detail
        return fetch(`${API_URL}/api/suggested-drink/${data.id}/detail/`, {
          credentials: 'include',
        });
      })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to fetch suggestedDrink detail.');
        return res.json();
      })
      .then((sd) => {
        setSuggestedDrink(sd);
        setSuggestedIngredients(sd.suggested_ingredients || []);

        // Pre-populate the selectedTags/selectedMedia for the form
        if (Array.isArray(sd.tags)) setSelectedTags(sd.tags.filter(tag => tag && tag.id));
        if (Array.isArray(sd.media_adapted_from)) setSelectedMedia(sd.media_adapted_from.filter(media => media && media.id));
      })
      .catch((err) => {
        console.error('Error in suggest-changes workflow:', err);
        alert('Failed to suggest changes. Check console.');
      })
      .finally(() => setSaving(false));
  }

  // 4) Toggle favorite
  function toggleFavorite() {
    const url = `${API_URL}/api/drinks/${id}/${isFavorite ? 'unfavorite' : 'favorite'}/`;
    const method = isFavorite ? 'DELETE' : 'POST';
    const csrftoken = getCookie('csrftoken');

    fetch(url, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
    })
      .then((res) => {
        if (res.ok) {
          setIsFavorite(!isFavorite);
        } else {
          console.error('Failed to toggle favorite:', res.statusText);
        }
      })
      .catch((err) => console.error('Error updating favorite:', err));
  }

  // Fetch ingredient attributes if not cached
  function fetchAttributesForIngredient(ingredientId) {
    if (!ingredientId) return;
    if (attributesMap[ingredientId]) {
      // Already fetched
      return;
    }
    fetch(`${API_URL}/api/get-ingredient-attributes/${ingredientId}/`, { credentials: 'include' })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to fetch ingredient attributes');
        return res.json();
      })
      .then((data) => {
        const newMap = { ...attributesMap };
        newMap[ingredientId] = data.attributes || [];
        setAttributesMap(newMap);
      })
      .catch((err) => {
        console.error('Error fetching attributes for ingredient', ingredientId, err);
      });
  }

  // 5) Save changes to the SuggestedDrink
  function handleSaveSuggestedDrink(e) {
    e.preventDefault();
    if (!suggestedDrink) return;
    setSaving(true);

    // We'll grab references to relevant fields
    const form = e.currentTarget;

    // Build the final ingredients array
    const finalIngredients = suggestedIngredients
        .filter(si => si.ingredient?.id && si.unit_fk?.id) // Ensure valid ingredient and unit
        .map(si => ({
            ingredient_id: si.ingredient.id,
            ingredient_attribute_id: si.ingredient_attribute?.id || null,
            quantity: si.quantity || 0,
            unit_fk_id: si.unit_fk.id,
        }));

    const payload = {
      name: form.name.value || suggestedDrink.name,
      preparation_method: form.preparation_method.value || suggestedDrink.preparation_method,
      notes: form.notes.value,
      process: form.process.value,
      tags: selectedTags.filter(tag => tag && tag.id).map(tag => tag.id),
      media_adapted_from: selectedMedia.filter(media => media && media.id).map(media => media.id),
      ingredients: suggestedIngredients
        .filter(si => si.ingredient?.id && si.unit_fk?.id) // Ensure valid ingredient and unit
        .map(si => ({
          ingredient_id: si.ingredient.id,
          ingredient_attribute_id: si.ingredient_attribute?.id || null,
          quantity: si.quantity || 0,
          unit_fk_id: si.unit_fk.id,
        })),
    };

    fetch(`${API_URL}/api/suggested-drink/${suggestedDrink.id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error || 'Failed to update suggested drink.');
          });
        }
        return res.json();
      })
      .then(() => {
        alert('Suggested changes saved successfully!');
        setIsSuggesting(false);
      })
      .catch((err) => {
        console.error('Error updating suggested changes:', err);
        alert('Could not save changes. Please try again.');
      })
      .finally(() => setSaving(false));
  }

  // ============= HANDLERS FOR SUGGESTED INGREDIENTS =============
  function handleAddIngredient() {
    setSuggestedIngredients((prev) => [
      ...prev,
      {
        ingredient: null,
        ingredient_attribute: null,
        quantity: '',
        unit_fk: null,
      },
    ]);
  }

  function handleRemoveIngredient(index) {
    setSuggestedIngredients((prev) => prev.filter((_, i) => i !== index));
  }

  function handleIngredientChange(index, newIngredientId) {
    const foundIng = availableIngredients.find((ing) => ing.id === newIngredientId);

    // Fetch attributes if the ingredient is valid and attributes aren't already cached
    if (newIngredientId && !attributesMap[newIngredientId]) {
      fetchAttributesForIngredient(newIngredientId);
    }

    setSuggestedIngredients((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        ingredient: foundIng ? { id: foundIng.id, name: foundIng.name } : null,
        ingredient_attribute: null, // Reset attribute when ingredient changes
      };
      return updated;
    });
  }

  function handleQuantityChange(index, newQty) {
    setSuggestedIngredients((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], quantity: newQty };
      return updated;
    });
  }

  function handleUnitChange(index, newUnitId) {
    const foundUnit = availableUnits.find((u) => u.id === newUnitId);
    setSuggestedIngredients((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        unit_fk: foundUnit || null,
      };
      return updated;
    });
  }

  function handleAttributeChange(index, newAttrId) {
    const ing = suggestedIngredients[index].ingredient;
    if (!ing) return;
    const relevant = attributesMap[ing.id] || [];
    const foundAttr = relevant.find((a) => a.id === newAttrId);
    setSuggestedIngredients((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        ingredient_attribute: foundAttr
          ? { id: foundAttr.id, attribute_type: foundAttr.attribute_type }
          : null,
      };
      return updated;
    });
  }

  // ============= RENDERING =============
  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading drink details...
        </Typography>
      </Container>
    );
  }

  if (!drink) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" align="center">
          Drink not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      {/* Original Drink */}
      <Paper elevation={3} sx={{ p: 3, mb: 3, position: 'relative' }}>
        {drink.cursed && (
          <SentimentVeryDissatisfiedIcon
            style={{ color: 'green', position: 'absolute', top: '8px', left: '8px', fontSize: '1.2rem' }}
            titleAccess="Cursed drink"
          />
        )}
        <Typography variant="h3" gutterBottom>
          {drink.name}
        </Typography>

        <Button
          variant="contained"
          color={isFavorite ? 'secondary' : 'primary'}
          onClick={toggleFavorite}
          sx={{ mb: 2 }}
        >
          {isFavorite ? 'Remove from KEEPER!' : 'Add to KEEPER!'}
        </Button>

        {drink.recipe_needs_review && (
          <Typography variant="body1" color="error" gutterBottom>
            Recipe needs review
          </Typography>
        )}
        {drink.notes_needs_review && (
          <Typography variant="body1" color="warning" gutterBottom>
            Notes need review
          </Typography>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Method:</strong> {drink.preparation_method}</Typography>
            <Typography><strong>ABV:</strong> {drink.abv}%</Typography>
            <Typography><strong>Notes:</strong> {drink.notes || 'None'}</Typography>
            <Typography><strong>Process:</strong> {drink.process || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Ingredients</Typography>
            <List>
              {(ingredients || []).map((ing, idx) => (
                <ListItem key={idx}>
                  <ListItemText
                    primary={`${Number(ing.quantity)
                      .toFixed(2)
                      .replace(/\.00$/, '')
                      .replace(/(\.\d)0$/, '$1')} ${
                      ing.unit_fk ? ing.unit_fk.name : ''
                    } ${ing.unit_fk ? 'of' : ''} ${
                      ing.ingredient_attribute
                        ? ing.ingredient_attribute.attribute_type
                        : ing.ingredient.name
                    }`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>

      {/* Suggest Changes Button */}
      {!drink.locked && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSuggestChanges}
          disabled={isSuggesting}
          sx={{ mb: 3 }}
        >
          {isSuggesting ? 'Processing...' : 'Suggest Changes'}
        </Button>
      )}

      {/* Suggested Drink Form (only if user is in "Suggesting" mode) */}
      {isSuggesting && suggestedDrink && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Edit Suggested Changes for {suggestedDrink.name}
          </Typography>

          {saving ? (
            <Typography color="textSecondary">Saving changes... Please wait.</Typography>
          ) : (
            <form onSubmit={handleSaveSuggestedDrink}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                {/* BASIC INFO SECTION */}
                <Typography variant="h6" gutterBottom>
                  Basic Info
                </Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Name"
                    name="name"
                    defaultValue={suggestedDrink.name}
                    variant="outlined"
                    fullWidth
                  />
                </Stack>
                <TextField
                  label="Preparation Method"
                  name="preparation_method"
                  defaultValue={suggestedDrink.preparation_method}
                  variant="outlined"
                  fullWidth
                />

                {/* GARNISH / NOTES / PROCESS */}
                <Stack direction="row" spacing={2}>
                </Stack>
                <TextField
                  label="Notes"
                  name="notes"
                  defaultValue={suggestedDrink.notes}
                  variant="outlined"
                  multiline
                  minRows={2}
                  fullWidth
                />
                <TextField
                  label="Process"
                  name="process"
                  defaultValue={suggestedDrink.process}
                  variant="outlined"
                  multiline
                  minRows={2}
                  fullWidth
                />

                {/* TAGS & MEDIA (AUTOCOMPLETE MULTISELECT) */}
                <Typography variant="h6" gutterBottom sx={{ pt: 2 }}>
                  Tags & Media
                </Typography>
                <Autocomplete
                  multiple
                  options={availableTags}
                  getOptionLabel={(option) => option.name}
                  value={selectedTags}
                  onChange={(_, newValue) => setSelectedTags(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Tags" placeholder="Pick tags" />
                  )}
                  sx={{ mb: 2 }}
                />
                <Autocomplete
                  multiple
                  options={availableMedia}
                  getOptionLabel={(option) => option.title}
                  value={selectedMedia}
                  onChange={(_, newValue) => setSelectedMedia(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Media Adapted From" placeholder="Pick media" />
                  )}
                />

                {/* INGREDIENTS TABLE */}
                <Typography variant="h6" gutterBottom sx={{ pt: 2 }}>
                  Suggested Ingredients
                </Typography>
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ingredient</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {suggestedIngredients.map((si, idx) => {
                        const currentIngId = si.ingredient?.id || null;
                        const relevantAttrs = currentIngId ? attributesMap[currentIngId] || [] : [];
                        return (
                          <TableRow key={idx}>
                            {/* Ingredient */}
                            <TableCell>
                              <Autocomplete
                                options={availableIngredients.sort((a, b) => a.name.localeCompare(b.name))} // Sort alphabetically
                                getOptionLabel={(option) => option.name || ''}
                                value={si.ingredient || null}
                                onChange={(_, newValue) => handleIngredientChange(idx, newValue?.id || null)} // Update ingredient when selected
                                renderInput={(params) => (
                                  <TextField {...params} label="Ingredient" variant="outlined" size="small" />
                                )}
                                sx={{
                                  maxHeight: 200, // Limit dropdown height
                                  overflowY: 'auto',
                                }}
                              />
                            </TableCell>

                            {/* Quantity */}
                            <TableCell sx={{ width: 100 }}>
                              <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                inputProps={{ step: 0.25 }}
                                value={si.quantity || ''}
                                onChange={(e) => handleQuantityChange(idx, e.target.value)}
                              />
                            </TableCell>

                            {/* Unit */}
                            <TableCell>
                              <Autocomplete
                                options={availableUnits.sort((a, b) => a.name.localeCompare(b.name))} // Sort alphabetically
                                getOptionLabel={(option) => option.name || ''}
                                value={si.unit_fk || null}
                                onChange={(_, newValue) => handleUnitChange(idx, newValue?.id || null)}
                                renderInput={(params) => (
                                  <TextField {...params} label="Unit" variant="outlined" size="small" />
                                )}
                                sx={{
                                  maxHeight: 200, // Limit dropdown height
                                  overflowY: 'auto',
                                }}
                              />
                            </TableCell>

                            {/* Attribute */}
                            <TableCell>
                              <Autocomplete
                                options={(attributesMap[si.ingredient?.id] || []).sort((a, b) =>
                                  a.attribute_type.localeCompare(b.attribute_type)
                                )} // Sort alphabetically
                                getOptionLabel={(option) => option.attribute_type || ''}
                                value={si.ingredient_attribute || null}
                                onChange={(_, newValue) => handleAttributeChange(idx, newValue?.id || null)}
                                renderInput={(params) => (
                                  <TextField {...params} label="Attribute" variant="outlined" size="small" />
                                )}
                                sx={{
                                  maxHeight: 200, // Limit dropdown height
                                  overflowY: 'auto',
                                }}
                              />
                            </TableCell>

                            {/* Remove Button */}
                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleRemoveIngredient(idx)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>

                        );
                      })}
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Button variant="contained" onClick={handleAddIngredient}>
                            + Add Ingredient
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Submit */}
                <Box mt={2}>
                  <Button variant="contained" color="primary" type="submit" disabled={saving} fullWidth>
                    Save Changes
                  </Button>
                </Box>
              </Stack>
            </form>
          )}
        </Paper>
      )}

      {/* Syrup Recipes */}
      {syrupDetails.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Associated Syrup Recipes</Typography>
          {syrupDetails.map((syr, idx) => (
            <Box key={idx} sx={{ mb: 2 }}>
              <Typography variant="h6">{syr.name}</Typography>
              <Typography>
                <strong>Type:</strong> {syr.type_of_syrup}
              </Typography>
              <Typography>
                <strong>Process:</strong> {syr.process || 'N/A'}
              </Typography>
              <Typography>
                <strong>Notes:</strong> {syr.notes || 'None'}
              </Typography>
              <Typography variant="h6">Ingredients</Typography>
              <List>
                {(syr.ingredients || []).map((si, i2) => (
                  <ListItem key={i2}>
                    <ListItemText
                      primary={`${Number(si.quantity)
                        .toFixed(2)
                        .replace(/\.00$/, '')
                        .replace(/(\.\d)0$/, '$1')} ${
                        si.unit_fk ? si.unit_fk.name : ''
                      } ${si.unit_fk ? 'of' : ''} ${si.ingredient.name}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Paper>
      )}

      {/* Media (Original Drink) */}
      {Array.isArray(drink.media_adapted_from) && drink.media_adapted_from.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Adapted From Media</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.media_adapted_from.map((m, idx) => (
              <Chip key={idx} label={m.title} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Tags (Original Drink) */}
      {Array.isArray(drink.tags) && drink.tags.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Tags</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.tags.map((tg, i2) => (
              <Chip key={i2} label={tg.name} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Episodes */}
      {Array.isArray(drink.episodes) && drink.episodes.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5">Episodes</Typography>
          <List>
            {drink.episodes.map((ep, i2) => (
              <ListItem key={i2}>
                <ListItemText
                  primary={
                    <Link to={`/episodes/${ep.id}`}>
                      {ep.name} (Aired on {ep.episode_air_date})
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      <Box mt={2} mb={4}>
        <Button onClick={() => navigate(-1)} variant="contained" color="primary" fullWidth>
          Back to Drinks
        </Button>
      </Box>
    </Container>
  );
}

export default DrinkDetails;
